import React from 'react'
import LogoImgSwitcher from './logoImgSwitcher'

const Loading = ({reference}) => {
    return (
    <div id="loading" ref={reference}>
        <LogoImgSwitcher />
    </div>
)}

export default Loading
