import React, { useEffect } from 'react'
import skillsJSON from '../../content/skills.json'
import { langContext } from "../layout"
import {SiHtml5,
        SiCss3,
        SiSass,
        SiJavascript,
        SiReact,
        SiGatsby,
        SiJquery,
        SiGithub,
	SiContentful,
	SiGraphql,
        SiFirebase,
        SiBootstrap} from "react-icons/si"
import mainJSON from "../../content/main.json"
import {ifElemisInview} from '../../utils/linkSync'

const ProgressBar = require('progressbar.js')

const skillIcons = [<SiHtml5/>,
                    <SiCss3/>,
                    <SiSass/>,
                    <SiBootstrap/>,
                    <SiJavascript/>,
                    <SiJquery/>,
                    // <SiReact/>,
                    // <SiGatsby/>,
                    // <SiGraphql/>,
                    <SiContentful/>,
                    <SiFirebase/>,
                    <SiGithub/>]


const {heading, skills} = skillsJSON
const {pages} = mainJSON
const pageId = pages[pages.findIndex(page => page.slug === '/skills')].id;
                    
const Skills = () => {
	const lang = React.useContext(langContext)

    let radVal;
    if(typeof window !== 'undefined'){
        const rootElem = getComputedStyle(document.documentElement),
            emVal = +rootElem.getPropertyValue('--emVal').split('px')[0];
        radVal = emVal * +rootElem.getPropertyValue('--radiusVal').split('em')[0];
    } else{
        radVal = 16;
    }

    const roundedRectData = (w, h, rVal) => {
        return 'M 0 ' + rVal
        + ' A ' + rVal + ' ' + rVal + ' 0 0 1 ' + rVal + ' 0'
        + ' L ' + (w - rVal) + ' 0'
        + ' A ' + rVal + ' ' + rVal + ' 0 0 1 ' + w + ' ' + rVal
        + ' L ' + w + ' ' + (h - rVal)
        + ' A ' + rVal + ' ' + rVal + ' 0 0 1 ' + (w - rVal) + ' ' + h
        + ' L ' + rVal + ' ' + h
        + ' A ' + rVal + ' ' + rVal + ' 0 0 1 0 ' + (h - rVal)
        + ' Z';
    };
    
    useEffect(() => {
        [...document.querySelectorAll(".skill")].forEach(skl => {
            const proBar = new ProgressBar.Path(skl.querySelector('.roundRectPath'), {
                strokeWidth: 4,
                trailWidth: 0,
                easing: 'bounce',
                duration: 2000,
                attachment: skl.querySelector('.percent'),
                step: function(_, path, attachment) {
                    let value = Math.round(path.value() * 100) + '%';
                    if (value === 0){
                        attachment.textContent = ''
                    } else {
                        attachment.textContent = value
                    }
                }
            });
            // start animation when the scroll arrives to it
            const scrollObject = document.querySelector(`#${pageId}`);
            let animationDone = false;
            window.addEventListener("scroll", () => {   
                if(!animationDone && ifElemisInview(scrollObject, 1)){
                    proBar.animate(+skl.dataset.per / 100);
                    animationDone = true
                }
            })
        })
    }, []);

    return (
        <section id={pageId}>
            <div className="container">
                <div className="heading">
                    <h2>{heading[lang].title}</h2>
                    <h3>{heading[lang].secondTitle}</h3>
                </div>
                <div className="content">
                    <div className="skills">
                        {skills.map((skl, i) => (
                            <div className="skillCont" key={i}>
                                <div className="skill" data-per={skl.percent}>
                                    <div className="icon">
                                        {skillIcons[i]}
                                    </div>
                                    <div className="progress">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="-5 -5 110 110">
                                            <path className="roundRectPath" d={roundedRectData(100, 100, radVal)}/>
                                        </svg>
                                    </div>
                                    <div className="name">
                                        <h4>
                                            {skl.name[lang]}
                                        </h4>
                                        <div className="percent"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills
