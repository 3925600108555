import React from 'react'
import { langContext, setLangContext } from "../layout"

const LangSwitcher = () => {
	const lang = React.useContext(langContext),
        setLang = React.useContext(setLangContext),
        langCond = lang === 'en'

    return (
        <button id="langSwitcher" onClick={() => langCond ? setLang('ar') : setLang('en')} aria-label="change language">
            {`${langCond ? 'Ar' : 'En'}`}
        </button>
    )
}

export default LangSwitcher
