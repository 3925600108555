import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Atropos from 'atropos/react'
import 'atropos/scss'
import projectsJSON from '../../content/projects.json'
import mainJSON from "../../content/main.json"
import FsProject from '../utils/fsProject'
import { langContext } from "../layout"

const {heading, showAllButton, projects} = projectsJSON
const {pages} = mainJSON
const pageId = pages[pages.findIndex(page => page.slug === '/projects')].id;

const Projects = ({fsPrInd}) => {
	const lang = React.useContext(langContext)

    const [projectMode, setprojectMode] = useState(fsPrInd !== -1)
    const [focusedProj, setfocusedProj] = useState(fsPrInd)
    const [atroposEff, setAtroposEff] = useState(false)
    const [showedAll, setShowedAll] = useState(false)
    // const [atroposEff, setAtroposEff] = useState(typeof window !== 'undefined'? window.innerWidth>=1200 : false)

    const handleClick = (index) => {
        setprojectMode(true)
        if(focusedProj !== index)
            setfocusedProj(index)
    }

    const handleAtroposShadow = (x, y, i) => {
        const divider = 15;
        [...document.querySelectorAll('.project .image')][i].style.boxShadow 
        =
        `${-y/divider}em ${x/divider}em .5em hsla(var(--bColThirdDeghsl), .25)`
    }

    const atroposEffWatcher = () => {
        if(window.innerWidth >= 1200)
            setAtroposEff(true)
        else
            setAtroposEff(false)
    }

    useEffect(() => {
        const bodyClassList = document.body.classList
        if(projectMode){
            if(bodyClassList.contains('normalOVF'))
                bodyClassList.remove('normalOVF')
        }else{
            if(!bodyClassList.contains('normalOVF'))
                bodyClassList.add('normalOVF')
        }
    }, [projectMode])

    useEffect(() => {
        atroposEffWatcher()
        window.onresize = function() {
            atroposEffWatcher()
        }
    }, [])

    
    const {allFile:{nodes: prImages}} = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "projects"}}, sort: {fields: name}) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 100)
                    }
                }
            }
        }
    `)

    return (
        <section id={pageId}>
                {
                    useMemo(() => 
                        <div className="container">
                            <div className="heading">
                                <h2>{heading[lang].title}</h2>
                                <h3>{heading[lang].secondTitle}</h3>
                            </div>
                            <div className="projects">
                                {projects.filter((_, ind) => !showedAll? ind < 6 : true).map((proj, i) => {
                                    const image = getImage(prImages[i])
                                    const projectElem = 
                                    <>
                                        <div className="image" data-atropos-offset="-5">
                                            <GatsbyImage
                                                image={image}
                                                alt="projectImage"/>
                                        </div>
                                        <div className="content" data-atropos-offset="0">
                                            <div className="tags"  data-atropos-offset="5">
                                                {proj[lang].tags.filter((_,k) => k < 4).map((tag, j) => {
                                                    if(j > 2)
                                                        return <span className="points" key={j}>...</span>
                                                    else
                                                        return <div className="tag" key={j}>{tag}</div>
                                                })}
                                            </div>
                                            <div className="title" data-atropos-offset="10">
                                                <h4>
                                                    {proj[lang].title}
                                                </h4>
                                            </div>
                                        </div>
                                    </>
                                    return (
                                        <Link to={`/projects/${proj.slug}`} onClick={() => handleClick(i)}>
                                        {
                                            atroposEff ? 
                                                <Fragment>
                                                    <Atropos
                                                        key={proj.slug}
                                                        onRotate={(x, y) => handleAtroposShadow(x, y, i)}
                                                        scaleClassName="project"
                                                        shadow={false}>
                                                            {projectElem}
                                                    </Atropos>
                                                </Fragment>
                                            :
                                                <Fragment>
                                                    <div className="project" key={proj.slug}>
                                                        {projectElem}
                                                    </div>
                                                </Fragment>
                                        }
                                        </Link>
                                    )
                                })}
                            </div>
                            {
                                !showedAll?
                                    <button
                                        className="show-all"
                                        aria-label={showAllButton.label[lang]}
                                        onClick={() => setShowedAll(true)} >
                                        {showAllButton.text[lang]}
                                    </button>
                                :
                                    null
                            }
                        </div>
                    , [lang, atroposEff, showedAll])
                }
            {
                (projectMode) ? 
                    <FsProject fProj={focusedProj} setfProj={setfocusedProj} setProjMd={setprojectMode} />
                :
                    null
            }
        </section>
    )
}

export default Projects
