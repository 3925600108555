import React, { useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

const CusScrollBar = (props) => {

    useEffect(() => {
        [...document.querySelectorAll('.scrollThumb')].forEach(elm => {
            elm.parentElement.classList.add('vScrollPr')
        });
    })

    const renderThumb = ({ style, ...sprops }) => (
        <div {...sprops} className={`scrollThumb`}></div>
    )

    return (
        <Scrollbars
        className="scrollContainer"
        renderThumbVertical={renderThumb}
        autoHide
        {...props}/>
    )
}

export default CusScrollBar
