import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import mainJSON from '../content/main.json'
import { langContext, themeContext } from "./layout"
import SEOImg from '../images/Seo logo.png'
import favicon from '../images/favicon.png'
import { useContext } from "react"

function Seo({title, meta, language}) {
	const langContVal = useContext(langContext)
	const themeContVal = useContext(themeContext)
	const lang = typeof langContVal == 'undefined'? language : langContVal
	const theme = typeof themeContVal == 'undefined'? (typeof window !== 'undefined' ? (localStorage.getItem('theme') || 'light') : 'light') : themeContVal

	const metaData = mainJSON.siteMetadata[lang],
		metaDescription = metaData.description,
		metaTitle = `${title} | ${metaData.title}`

	return (
	<Helmet
		link={[
			{
				rel: 'icon',
				type: 'image/png',
				href: favicon
			}
		]}
		htmlAttributes={{
			lang,
			dir: lang === 'en' ? 'ltr' : 'rtl',
			theme
		}}
		title={metaTitle}
		meta={[
			{
			name: `description`,
			content: metaDescription,
			},
			{
			property: `og:title`,
			content: metaTitle,
			},
			{
			property: `og:description`,
			content: metaDescription,
			},
			{
			property: `og:type`,
			content: `website`,
			},
			{
			property: `og:image`,
			content: SEOImg,
			},
			{
			name: `twitter:card`,
			content: `summary`,
			},
			{
			name: `twitter:creator`,
			content: metaData?.author || ``,
			},
			{
			name: `twitter:title`,
			content: metaTitle,
			},
			{
			name: `twitter:description`,
			content: metaDescription,
			},
			{
			name: `twitter:image`,
			content: SEOImg,
			},
		].concat(meta)}
	/>
)
}

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
	title: 'About'
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default Seo
