import React, { useMemo, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import fsProjectsJSON from '../../content/fsProjects.json'
import projectsJSON from '../../content/projects.json'
import { langContext } from "../layout"

import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FaLink, FaTools, FaCalendar, FaTimes, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import CusScrollBar from './cusScrollBar';

// Import Swiper styles
import 'swiper/swiper.scss'
import { useEffect, useState } from 'react';
import "../../sass/fsProject.scss"

// install Virtual module
SwiperCore.use([Virtual, Pagination, Navigation]);

const {projects} = projectsJSON;

const FsProject = ({fProj, setfProj, setProjMd}) => {
	const lang = React.useContext(langContext)

    const [isMobileView, setIsMobileView] = useState(typeof window !== 'undefined'? window.innerWidth <= 500 : false)

    const handleClick = () => {
        setProjMd(false)
        document.querySelector('nav a[href="/projects"]').click()
    }

    const syncIndex = swiper => {
        setfProj(swiper.activeIndex)
        document.querySelectorAll('.projects a')[swiper.activeIndex].click()
    }

    useEffect(() => {
        window.onresize = () => {
            if(window.innerWidth <= 500)
                setIsMobileView(true)
            else
                setIsMobileView(false)
        }
    }, [isMobileView])


    const swiperRef = useRef()
    useEffect(() => {
        if(lang === "ar"){
            swiperRef.current.swiper.rtl = true
            swiperRef.current.swiper.rtlTranslate = true
            swiperRef.current.swiper.update()
        }
    }, [])

    const {allFile:{nodes: images}} = useStaticQuery(graphql`
        {
            allFile(
                filter: {relativeDirectory: {regex: "/fs-projects/"}}
                sort: {fields: relativePath}) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 70)
                    }
                }
            }
        }
    `)

    const getImageIndex = React.useCallback((ind) => isMobileView? ind + fsProjectsJSON.length : ind, [isMobileView])

    return (
        <div id="fsProject">
            {
                useMemo(() => 
                    <Swiper virtual
                        pagination={{
                            el: '#fsProject .pagination',
                            bulletClass: 'bullet',
                            bulletActiveClass: 'active',
                            clickable:true
                        }}
                        navigation={
                            (lang === 'en')? {
                                nextEl: '#fsProject .rightArrow',
                                prevEl: '#fsProject .leftArrow',
                            }
                            :
                            {
                                nextEl: '#fsProject .leftArrow',
                                prevEl: '#fsProject .rightArrow',
                            }
                        }
                        initialSlide={fProj}
                        onSlideChange={syncIndex}
                        grabCursor
                        ref={swiperRef}>
                            <button className="leftArrow" slot="container-start" aria-label="next slide">
                                <FaAngleLeft />
                            </button>
                            <button className="rightArrow" slot="container-end" aria-label="previous slide">
                                <FaAngleRight />
                            </button>
                            <button className="close" slot="container-end" onClick={() => handleClick()} aria-label="close">
                                <FaTimes />
                            </button>
                            <div className="pagination" slot="container-end"></div>
                        {fsProjectsJSON.map((slideContent, index) => {
                            const imageIndex = getImageIndex(index)
                            const image = getImage(images[imageIndex])

                            return (
                            <SwiperSlide key={index} virtualIndex={index} >
                                <div className="card">
                                    <div className="imageCont">
                                        <CusScrollBar>
                                            <GatsbyImage
                                            image={image}
                                            alt={`Othwsav | ${projects[index][lang].title}`}
                                            className="image"/>
                                        </CusScrollBar>
                                    </div>
                                        <div className="content">
                                            <div className="title">
                                                <h5>{projects[index][lang].title}</h5>
                                            </div>
                                            <div className="description">
                                                <p>
                                                    {slideContent[lang].description}
                                                </p>
                                            </div>
                                            <div className="linkDiv sameSec">
                                                <span className="icon">
                                                    <FaLink />
                                                </span>
                                                <div className="link">
                                                {
                                                    (slideContent.link !== '') ?
                                                        <a href={slideContent.link} target="_blank" rel="noopener noreferrer">
                                                            {slideContent.link}
                                                        </a>
                                                    :
                                                        (lang === 'en')? 'link unavailable' : 'الرابط غير موجود'
                                                }
                                                </div>
                                            </div>
                                            <div className="toolsDiv sameSec">
                                                <span className="icon">
                                                    <FaTools />
                                                </span>
                                                <div className="tools">
                                                    {projects[index][lang].tags.map((tl, i) => (
                                                        <div className="tool" key={i}>
                                                            {tl}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="dateDiv sameSec">
                                                <span className="icon">
                                                    <FaCalendar />
                                                </span>
                                                <div className="date">
                                                    {`${slideContent.date[0]} - ${
                                                        (slideContent.date[1] !== '') ? 
                                                            slideContent.date[1]
                                                        :
                                                            (lang === 'en')? 'now' : 'الآن'
                                                    }`}
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </SwiperSlide>
                            )
                        })}
                    </Swiper>
                
                , [])
            }
        </div>
    )
}

export default FsProject
