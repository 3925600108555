import * as React from "react"
import { useState, useEffect, useLayoutEffect, createContext } from "react"
import {linkSyncer, linkOnclickEvent, dynamiScrollIntoView, S} from '../utils/linkSync'
import Navbar from "./layout/navbar"
import Seo from "./seo"
import "normalize.css"
import "../sass/style.scss"
import Header from "./layout/Header"
import Skills from "./layout/skills"
//import WhyMe from "./layout/whyMe"
import Projects from "./layout/projects"
//import Services from "./layout/services"
// import Testimonials from "./layout/testimonials"
// import CallToAction from "./layout/callToAction"
import Contact from "./layout/contact"
import Footer from "./layout/footer"
import mainJSON from '../content/main.json'
import projectsJSON from '../content/projects.json'
import Loading from "./utils/loading";
import { useRef } from "react";
import { useMemo } from "react"
import Experience from "./layout/experience"

const projectsSlug = projectsJSON.projects.map(project => project.slug)
const projectsTitle = projectsJSON.projects.map(project => {
	return {
		en: project.en.title,
		ar: project.ar.title
	}
})
const pagesSlug = mainJSON.pages.map(page => page.slug)
const pagesTitle = mainJSON.pages.map(page => page.title)
const pagesId = mainJSON.pages.map(page => page.id)

export const langContext = createContext()
export const setLangContext = createContext()
export const themeContext = createContext()
export const setThemeContext = createContext()

const Layout = (props) => {

	// pathing code
	const path = props.uri || '';
	const isPagePath = pagesSlug.includes(path)
	const numberOfPage = isPagePath? pagesSlug.indexOf(path) : 'false'

	// projects paths code
	const isPrPath = isPagePath? 'false' : path.includes('/projects/') && projectsSlug.includes(path.replace('/projects/', ''));
	const prPath = isPagePath? 'false' : path.replace('/projects/', '')
	const prPathIndex = isPagePath? -1 : projectsSlug.indexOf(prPath)

	const isNormalPage = isPagePath || isPrPath

	const [lang, setLang] = useState(() => (typeof window !== 'undefined' ? (localStorage.getItem('lang') || (/^ar\b/.test(navigator.language) ? 'ar' : 'en')) : 'en'))
	const [theme, setTheme] = useState(() => (typeof window !== 'undefined' ? (localStorage.getItem('theme') || 'light') : 'light'))
	//const [selectedPck, setSelectedPck] = useState(0)

	const returnPageTitle = num => {
		if(isPagePath)
			return pagesTitle[num][lang]
		else
			return titleCase(projectsTitle[num][lang])
	};

	const titleCase = str => str.replace(/\b\S/g, t => t.toUpperCase());
	
	useEffect(() => {
		if(isPagePath){
			linkOnclickEvent()
			dynamiScrollIntoView(S(`#${pagesId[numberOfPage]}`))
		}
	}, [numberOfPage, isPagePath])

	useEffect(() => {
		if(isPagePath){
			linkSyncer()
			linkOnclickEvent()
		}
	}, [isPagePath])
		
	// setting local Storage for theme state
	useEffect(() => {
		localStorage.setItem('lang', lang);
	}, [lang])
	useEffect(() => {
		localStorage.setItem('theme', theme);
	}, [theme])

	// loading element remover
	const loading = useRef()
	useEffect(() => {
		if(loading.current){
			loading.current.style.opacity = "0"
			setTimeout(() => {
				loading.current.remove()
				if(isPagePath)
					document.body.classList.add('normalOVF')
			}, 250);
		}
	}, [isPagePath])

	// Memoized Elements
	const MemoizedNavbar = useMemo(() => <Navbar/>, [theme, lang])
	const memoizedElements = useMemo(() => 
		<>
			<Header/>
			<Skills/>
			<Experience/>
			<Projects fsPrInd={prPathIndex} />
			{/* <Testimonials /> */}
			{/* <CallToAction /> */}
			{/*<Services setPck={setSelectedPck} />*/}
		</>
	, [])
	// const MemoizedContactElem = useMemo(() => <Contact selectedPck={selectedPck} />, [selectedPck])
	const MemoizedContactElem = useMemo(() => <Contact />, [])
	const MemoizedFooterElem = useMemo(() => <Footer />, [theme])
	const MemoizedLoadingElem = useMemo(() => <Loading reference={loading} />, [])


	return (
		<>
			{
			(isNormalPage)?
				<>
					<main>
						<themeContext.Provider value={theme}>
							{MemoizedLoadingElem}
						</themeContext.Provider>
						<langContext.Provider value={lang}>
								<setLangContext.Provider value={setLang}>
									<setThemeContext.Provider value={setTheme}>
										<themeContext.Provider value={theme}>
											{MemoizedNavbar}
										</themeContext.Provider>
									</setThemeContext.Provider>
								</setLangContext.Provider>
								<themeContext.Provider value={theme}>
									<Seo title={returnPageTitle(isPagePath? numberOfPage : prPathIndex)} />
								</themeContext.Provider>
								{memoizedElements}
								{MemoizedContactElem}
								<themeContext.Provider value={theme}>
									{MemoizedFooterElem}
								</themeContext.Provider>
						</langContext.Provider>
					</main>
				</>
			:
				<>
					{props.children}
				</>
			}
		</>
	)
}

export default Layout
