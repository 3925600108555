import React from 'react'
import { FaMoon, FaSun } from 'react-icons/fa'
import { themeContext, setThemeContext } from "../layout"

const ThemeSwitcher = () => {
	const theme = React.useContext(themeContext),
        setTheme = React.useContext(setThemeContext),
        themeCond = theme === 'dark'

    return (
        <button id="themeSwitcher" onClick={() => themeCond ? setTheme('light') : setTheme('dark')} aria-label="change theme">
            {themeCond ? <FaSun/> : <FaMoon/>}
        </button>
    )
}

export default ThemeSwitcher
