import * as React from "react"
import { Link } from "gatsby"
import mainJSON from '../../content/main.json'
import { langContext, themeContext } from "../layout"
import LangSwitcher from "../utils/langSwitcher"
import ThemeSwitcher from "../utils/themeSwitcher"
import { FaBars } from "react-icons/fa"
import LogoImgSwitcher from "../utils/logoImgSwitcher"
import { useEffect } from "react"
import { S } from "../../utils/linkSync"
import { useMemo } from "react"

const {pages} = mainJSON

const Navbar = () => {
	const lang = React.useContext(langContext)
	const theme = React.useContext(themeContext)
	const [show, setShow] = React.useState(false)
	const [narrow, setNarrow] = React.useState(typeof window != "undefined"? (window.scrollY !== 0) : false)

	useEffect(() => {
		window.onresize = function() {
			if(show && window.innerWidth >= 992){
				setShow(false)
			}
		}
	}, [show])

	useEffect(() => {
		if(show)
			S('main').classList.add('longNavbar')
		else
			S('main').classList.remove('longNavbar')
	}, [show])

	useEffect(() => {
		window.onscroll = () => {
			if(window.scrollY !== 0){
				setNarrow(true)
			} else {
				setNarrow(false)
			}
		}
	}, [])

	
	// Memoized Elements
	const mzdThemeElems = useMemo(() => {
		return {
			logo: <LogoImgSwitcher />,
			themeSwitch: <ThemeSwitcher />
		}
	}, [theme]),
	mzdLangSwitchElem = useMemo(() => <LangSwitcher />, [lang]),
	mzdNullElems = useMemo(() => {
		return {
			switchers: (
				<div className="switchers">
					{mzdLangSwitchElem}
					{mzdThemeElems.themeSwitch}
				</div>
			),
			barsIcon: <FaBars/>
		}
	}, []),
	mzdLinksElem = useMemo(() => 
		<div className={`links${show ? ' show' : ''}`}>
			{pages.map((page, i) => (
					<span key={i}>
						<Link to={page.slug} data-to={page.id}>{page.title[lang]}</Link>
					</span>
			))}
			{mzdNullElems.switchers}
		</div>
	, [show, lang]),
	mzdButtonElem = useMemo(() => 
		<button className="navSwitcher" onClick={() => setShow(!show)} aria-label="show or hide navbar links">
			{mzdNullElems.barsIcon}
		</button>
	, [show])
	
	return (
		<>
			<nav>
				<div className="container">
					<div className={`nav${narrow ? ' narrow' : ''}`}>
						<div className="upperBar">
							<div className="logo">
								{mzdThemeElems.logo}
							</div>
							{mzdButtonElem}
						</div>
						{mzdLinksElem}
					</div>
				</div>
			</nav>
		</>
)}

export default Navbar
