import React, { Fragment } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { langContext } from "../layout"
import exJSON from '../../content/experience.json';
import { FaTools } from "react-icons/fa";

const {heading, experience} = exJSON
const now = {
    en: "now",
    ar: "الآن"
}
const month = {
    en: "mos",
    ar: "شهر"
}
const year = {
    en: "yrs",
    ar: "عام"
}

const Experience = () => {
	const lang = React.useContext(langContext)

    const {allFile:{nodes: expImages}} = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "experience"}}, sort: {fields: name}) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 100)
                    }
                }
            }
        }
    `)

    return (
        <section id="ex">
            <div className="container">
                <div className="heading">
                    <h2>{heading[lang].title}</h2>
                    <h3>{heading[lang].secondTitle}</h3>
                </div>
                <div className="experience">
                    {experience.map((exp, i, ar) => {
                        const image = getImage(expImages[i])
                        const diffDate = dateDiffInMonthsAndYears(exp.date.from, exp.date.to)
                        console.log(diffDate)
                        return (
                            <Fragment key={exp.id}>
                                <div className="exp">
                                    <div className="image">
                                        <GatsbyImage
                                            image={image}
                                            alt={exp.imgAlt}/>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h4>{exp.title[lang]}</h4>
                                            <h5>{exp.employee[lang]}</h5>
                                            <h6>
                                                <span>{exp.date.from}</span>
                                                -
                                                <span>{exp.date.to === "" ? now[lang] : exp.date.to}</span>
                                                (<span className="duration">
                                                    {diffDate.map((date, i) => (
                                                        <Fragment key={date}>
                                                            {date !== 0 ?
                                                                <>
                                                                    {`${date} ${i === 0 ?
                                                                        year[lang]
                                                                    :
                                                                        month[lang]
                                                                    } `}
                                                                </>
                                                            :
                                                                null
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </span>)
                                            </h6>
                                        </div>
                                        <div className="description">
                                            <p>{exp.description[lang]}</p>
                                        </div>
                                        <div className="tools">
                                            {exp.tools.map(tl => (
                                                <span className="tool" key={tl.id}>{tl[lang]}</span>
                                            ))}
                                        </div>
                                    </div>
                                    {
                                        i < ar.length-1 ?
                                            <>
                                                <div className="linker">
                                                    <span className="start"></span>
                                                    <span className="stick"></span>
                                                    <span className="end"></span>
                                                </div>
                                                <span className="desktop-stick"></span>
                                            </>
                                        :
                                            null
                                    }
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </section>
    )
    
};

export default Experience;

function dateDiffInMonthsAndYears(start, end) {
    let newStart = start.split("/"), newEnd, k=0;
    newStart = new Date(`${newStart[1]}-${newStart[0]}-01`)
    if(end !== ""){
        newEnd = end.split("/")
        newEnd = new Date(`${newEnd[1]}-${newEnd[0]}-01`)
    } else {
        newEnd = new Date()
        k = 1
    }

    const diffDate = new Date(Math.ceil((newEnd - newStart)))
    return [diffDate.getFullYear() - 1970, diffDate.getMonth() + k];
}

