import React, { useMemo, useRef, useState } from 'react'
import { langContext } from "../layout"
import contactJSON from '../../content/contact.json'
//import servicesMinJSON from '../../content/services.min.json'
//import servicesJSON from '../../content/services.json'
import { useEffect } from 'react'
import mainJSON from "../../content/main.json"

const {heading, groups, form} = contactJSON;
/*const services = servicesMinJSON.services.map(service => {
    return {
        val: service.val,
        name: service.name
    }
});*/
//const numOfFeatures = [...servicesJSON.services.map(service => service.numOfFeatures), 0]
//const {features} = servicesJSON;
const {pages} = mainJSON
const pageId = pages[pages.findIndex(page => page.slug === '/contact')].id;
//const returnfeasArr = (index) => (numOfFeatures[index] !== 'all')? new Array(features.length).fill(false).map((_, i) => i < numOfFeatures[index]) : new Array(features.length).fill(true)

const Contact = () => {
    const lang = React.useContext(langContext);

    /*const [projectInfo, setProjectInfo] = useState({
        ...services[selectedPck],
        features: returnfeasArr(selectedPck),
    })*/

    const [userInfo, setUserInfo] = useState({
        name: "",
        email: "",
        message: ""
    })

    /*const changePack = index => {
        const selectedPack = services[index]
        setProjectInfo({
            ...projectInfo,
            ...selectedPack,
            features: returnfeasArr(index)
        })
    }*/

    /*const handlePckChange = e => {
        const packVal = e.target.value,
            selectedPackIndex = services.findIndex(srv => srv.val === packVal)
        changePack(selectedPackIndex)
    }*/

    const handleInputChange = e => {
        setUserInfo({
            ...userInfo,
            [e.target.id]: e.target.value,
        })
    }

    //const checkboxInput = useRef([])

    /*const handleCheckChange = (e) => {
        const feasIndex = checkboxInput.current.indexOf(e.target)
        const newFeasArray = projectInfo.features
        newFeasArray.splice(feasIndex, 1, !newFeasArray[feasIndex])
        setProjectInfo({
            ...projectInfo,
            features: newFeasArray
        })
        if(projectInfo.val !== 'cu'){
            setProjectInfo({
                ...projectInfo,
                ...services[services.length - 1]
            })

            const feildLighterElem = document.createElement('span');
            feildLighterElem.classList.add('feildLighter');
            const $ = e => document.querySelector(e)
            $('.field.pack').append(feildLighterElem);
            setTimeout(() => {
                $('span.feildLighter').remove()
            }, 1000)
        }
    }*/

    /*useEffect(() => {
        changePack(selectedPck)
    }, [selectedPck])*/

    return (
        <section id={pageId}>
            <div className="container">
                {
                    useMemo(() => 
                        <div className="heading">
                            <h2>{heading[lang].title}</h2>
                            <h3>{heading[lang].secondTitle}</h3>
                        </div>
                    , [lang])
                }
                <div className="contactForm">
                    <form action="https://formspree.io/f/xnqlkbrd" method="POST">
                        <div className="feilds-group">
                            <h4>{groups[0][lang]}</h4>
                            <div className="field name">
                                {
                                    useMemo(() => 
                                        <label htmlFor={form.name.id}>
                                            {form.name.label[lang]}
                                        </label>
                                    , [lang])
                                }
                                {
                                    useMemo(() => 
                                        <input value={userInfo.name} onChange={handleInputChange}
                                            id={form.name.id} name={form.name.id} type="text"
                                            placeholder={form.name.placeholder[lang]} required />
                                    , [userInfo, lang])
                                }
                            </div>
                            <div className="field email">
                                {
                                    useMemo(() => 
                                        <label htmlFor={form.email.id}>
                                            {form.email.label[lang]}
                                        </label>
                                    , [lang])
                                }
                                {
                                    useMemo(() => 
                                        <input value={userInfo.email} onChange={handleInputChange}
                                            id={form.email.id} name={form.email.id} type="email"
                                            placeholder={form.email.placeholder[lang]} required />
                                    , [userInfo, lang])
                                }
                            </div>
                        </div>
                        <div className="feilds-group">
                            <h4>{groups[1][lang]}</h4>
                            {/*<div className="field pack">
                                {
                                    useMemo(() => 
                                        <label htmlFor={form.pack.id}>
                                            {form.pack.label[lang]}
                                        </label>
                                    , [lang])
                                }
                                {
                                    useMemo(() => 
                                        <select value={projectInfo.val} onChange={handlePckChange}
                                            id={form.pack.id} name={form.pack.id} >
                                            {services.map((opt, i) => (
                                                <option value={opt.val} key={i}>{opt.name[lang]}</option>
                                            ))}
                                        </select>
                                    , [projectInfo.val, lang])
                                }
                            </div>
                            <div className="field features" >
                                {
                                    useMemo(() => 
                                        <label htmlFor={form.features.id}>
                                            {form.features.label[lang]}
                                        </label>
                                    , [lang])
                                }
                                {
                                    useMemo(() => 
                                        <div>
                                            {
                                                features.map((feature, i) => {
                                                    return (
                                                        <label className="checkbox-container" key={feature.id}>
                                                            <input
                                                                value={feature[lang]}
                                                                ref={(el) => {checkboxInput.current[i] = el}}
                                                                checked={projectInfo.features[i]}
                                                                onChange={handleCheckChange}
                                                                name={form.features.id}
                                                                type="checkbox" />
                                                            <span>{feature[lang]}</span>
                                                        </label>
                                                    )
                                                })
                                            }
                                        </div>
                                    , [projectInfo, lang])
                                }
                            </div>*/}
                            <div className="field message">
                                {
                                    useMemo(() => 
                                        <label htmlFor={form.message.id}>
                                            {form.message.label[lang]}
                                        </label>
                                    , [lang])
                                }
                                {
                                    useMemo(() => 
                                        <textarea value={userInfo.message} onChange={handleInputChange}
                                            id={form.message.id} name={form.message.id}
                                            placeholder={form.message.placeholder[lang]} required />
                                    , [userInfo, lang])
                                }
                            </div>
                        </div>
                        {
                            useMemo(() => 
                                <div className="button">
                                    <button type="submit" id={form.submit.id} name={form.submit.id} aria-label="send">
                                        {form.submit.value[lang]}
                                    </button>
                                </div>
                            , [lang])
                        }
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contact
