import React from 'react'
import logo from "../../images/logo.svg"
import darkLogo from "../../images/logo dark.svg"
import { themeContext } from "../layout"

const LogoImgSwitcher = () => {
	const theme = React.useContext(themeContext)

    return (
        <div className="logoImg">
            {
                theme === 'light'?
                <img src={logo} alt="othwsav logo"/>
                :
                <img src={darkLogo} alt="othwsav dark logo"/>

            }
        </div>
    )
}

export default LogoImgSwitcher
