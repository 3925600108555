import React from 'react'
import headerJSON from '../../content/header.json'
import { langContext } from "../layout"
import { socialMedia } from '../../content/socialMedia'
import { StaticImage } from 'gatsby-plugin-image'
import mainJSON from "../../content/main.json"
import anSvgImg from "../../images/header/animated svg.svg"

const {content} = headerJSON
const {pages} = mainJSON
const pageId = pages[pages.findIndex(page => page.slug === '/')].id;
const projectsPageId = pages[pages.findIndex(page => page.slug === '/projects')].id
const contactPageId = pages[pages.findIndex(page => page.slug === '/contact')].id

const Header = () => {
	const lang = React.useContext(langContext)

    return (
    <header id={pageId}>
        <div className="container">
            <article>
                <span>{content[lang].hello}</span>
                <h1>{content[lang].heading}</h1>
                <p>{content[lang].description}</p>
                <div className="buttons">
                    {content[lang].buttons.map((btn, i) => (
                        <button key={i} data-to={i === 0 ? `${projectsPageId}` : `${contactPageId}`} aria-label={btn}>
                            {btn}
                        </button>
                    ))}
                </div>
            </article>
            <article>
                <StaticImage 
                    src="../../images/header/masked picture.png"
                    alt="othwsav"
                    layout="constrained"
                    placeholder="tracedSVG"
                    className="picture"
                    quality="100"
                />
                <img src={anSvgImg} height="100%" width="100%" alt="othwsav portfolio header aniamted svg background" />
            </article>
            <aside>
                {socialMedia.map((med, i) => (
                    <a href={med.link} target="_blank" key={i} rel="noopener noreferrer">
                        {med.elem}
                    </a>
                ))}
            </aside>
        </div>
    </header>
    )
}

export default Header
