import React, { useMemo } from 'react'
import { langContext } from "../layout"
import footerJSON from '../../content/footer.json'
import { FaEnvelope } from 'react-icons/fa';
import { SiStackshare, SiStackoverflow } from 'react-icons/si';
import LogoImgSwitcher from "../utils/logoImgSwitcher"
import { socialMedia } from '../../content/socialMedia'
// import { SiFiverr, SiFreelancer } from 'react-icons/si';
import { Link } from 'gatsby';
import { useContext } from 'react';

// const {fPlatforms, contact, headings} = footerJSON;
const {contact, headings} = footerJSON;

const Footer = () => {
    const lang = useContext(langContext);
    
    const contactIcons = useMemo(() => 
        [
            <FaEnvelope/>,
            <SiStackshare/>,
            <SiStackoverflow/>
        ]
    , []) 
    // const freelancingIcons = useMemo(() => 
    //     [
    //         <SiFiverr/>,
    //         <span id="mostaql"></span>,
    //         <SiFreelancer/>
    //     ]
    // , []) 

    const handleEmailClick = (e) => {
        const email = "contact"
        navigator.clipboard.writeText(email + "@othswav.com");
        const previousTextCon = lang === "en" ? "Copy to clipboard" : "انسخ إلى الحافظة"
        e.target.firstChild.textContent = lang == "en"? "E-mail copied to clipboard successfully" : "تم نسخ البريد الإلكتروني بنجاح"
        setTimeout(() => {
            e.target.firstChild.textContent = previousTextCon
        }, 1000)
    }

    return (
        <footer>
            <div className="container">
                <div className="content">
                    <div className="logo">
						<LogoImgSwitcher />
                        <span>{lang === 'en' ? 'Othwsav' : 'عثمان'}</span>
                    </div>
                    {
                        useMemo(() => 
                        <>
                            {/* <div className="fPlatforms">
                                <h4>{headings[0][lang]}</h4>
                                <div>
                                    {fPlatforms.map((fplt, i) =>
                                        <div className="freelance" key={i}>
                                            <span className="icon">
                                                {freelancingIcons[i]}
                                            </span>
                                            <a href={fplt.link} target="_blank" rel="noopener noreferrer" key={i}>
                                                {fplt.name[lang]}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div className="contacts">
                                <h4>{headings[1][lang]}</h4>
                                <div>
                                    {contact.map((ct, i) =>
                                        <div className="contact" key={i}>
                                            <span className="icon">
                                                {contactIcons[i]}
                                            </span>
                                            {(ct.text.en === 'e-mail')?
                                                <button aria-label="get email" onClick={(e) => handleEmailClick(e)}>
                                                    <span class="tooltip">{
                                                        lang === "en" ? 
                                                            "Copy to clipboard"
                                                        :
                                                            "انسخ إلى الحافظة"
                                                    }</span>
                                                    {ct.text[lang]}
                                                </button>
                                            :
                                                <a href={ct.link} target="_blank" rel="noopener noreferrer">
                                                    {ct.text[lang]}
                                                </a>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="socialMedia">
                                <h4>{headings[2][lang]}</h4>
                                <div>
                                    {socialMedia.map((med, i) =>
                                        <a href={med.link} target="_blank" key={i} rel="noopener noreferrer">
                                            {med.elem}
                                        </a>
                                    )}
                                </div>
                            </div>
                        </>
                        , [lang])
                    }
                </div>
                {
                    useMemo(() => 
                        <div className="copyright">
                            <>© {new Date().getFullYear()}</>
                            {
                                lang === 'en' ?
                                <>
                                    , developed by
                                    {' '}
                                    <Link to="/" data-to="ho">Othwsav</Link>
                                    , All rights Reserved.
                                </>
                                :
                                <>
                                    ، مطور من طرف
                                    {' '}
                                    <Link to="/" data-to="ho">عثمان</Link>
                                    ، جميع الحقوق محفوظة.
                                </>
                            }
                        </div>
                    , [lang])
                }
            </div>
        </footer>
    )
}

export default Footer
