
import React from 'react'

import { SiLinkedin, SiTwitter, SiInstagram } from "react-icons/si";
import { SiCodewars } from "react-icons/si";
import { SiGithub } from "react-icons/si";

export const socialMedia = [
    {
        link: 'https://twitter.com/OTHWSAV',
        elem: <SiTwitter />
    },
    {
        link: 'https://www.instagram.com/othwsav/',
        elem: <SiInstagram />
    },
    {
        link: 'https://www.linkedin.com/in/othwsav',
        elem: <SiLinkedin />
    },
    {
        link: 'https://github.com/othwsav',
        elem: <SiGithub />
    },
    {
        link: 'https://www.codewars.com/users/othwsav',
        elem: <SiCodewars />
    },
    // {
    //     link: 'https://stackshare.io/othwsav',
    //     elem: <SiStackshare />
    // },
    // {
    //     link: 'https://stackoverflow.com/users/17366732/othwsav',
    //     elem: <SiStackoverflow />
    // }
]