export const S = (e) => document.querySelector(e);
export const SA = (e) => document.querySelectorAll(e);

const changeActiveClass = (i) => {
    if(![...SA(`nav .links a`)][i].classList.contains('active')){
        [...SA(`nav .links a`)].forEach(elm => {
            elm.classList.remove('active');
        });
        // [...SA(`nav .links a`)].filter((_, ind) => ind !== i).forEach(elm => {
        //     elm.classList.remove('active');
        // });
        [...SA(`nav .links a`)][i].classList.add('active');
    }
}

export const ifElemisInview = (element, index) => {

    const
        navH = S('.nav').getBoundingClientRect().height,
        visibleHeight = window.innerHeight - navH,
        elemH = element.getBoundingClientRect().height,
        elemTop = element.getBoundingClientRect().top,
        elemBot = element.getBoundingClientRect().bottom,
        isElemTallerThanWindow = elemH > visibleHeight,
        marginConstant = 48;

    if(elemTop < window.innerHeight && elemBot > 0){
        if(isElemTallerThanWindow){
            if(elemTop <= (visibleHeight / 2) + navH && elemBot >= (visibleHeight / 2) + navH){
                return true;
            }
        } else {
            if(elemTop >= ((visibleHeight - elemH) / 2) + navH - marginConstant && elemBot <= ((visibleHeight - elemH) / 2) + navH + elemH + marginConstant){
                return true;
            }
        }
        if(index === 0){
            if(elemBot >= (elemH / 2) + navH){
                return true;
            }
        }
    }
}

export const linkSyncer = () => {
    window.addEventListener('scroll', () => {
        SA('header, section:not(#callToAction)').forEach((e, i) => {
            if(ifElemisInview(e, i)){
                changeActiveClass(i)
            }
        })
    })
}

export const linkOnclickEvent = () => {
    [...SA(`[data-to]`)].forEach(el => {
        el.onclick = () => {
            dynamiScrollIntoView(S(`#${el.dataset.to}`))
        }
    })
}

export const dynamiScrollIntoView = (elem) => {
    const 
        navH = S('.nav').getBoundingClientRect().height,
        visibleHeight = window.innerHeight - navH,
        elemH = elem.getBoundingClientRect().height,
        isElemTallerThanWindow = elemH > visibleHeight;

    let scrollBlockOpt = "start"

    if(!isElemTallerThanWindow)
        scrollBlockOpt = "center"

    elem.scrollIntoView({
        behavior: "smooth",
        block: scrollBlockOpt
    })

}
